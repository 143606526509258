import React from "react";
import "./Education.css";
//import {Navbar} from './Components/Navbar';

const Education = () => {
  return (
    <div className="portfolio__education">
      <h1>Education</h1>
      <p id="edu_intro">
        This section provides an overview of the courses I have undertaken
        throughout my academic journey at the university. If your are interested
        in my professional experience, take a look at my resume{" "}
      </p>

      <h2>Mathematics</h2>
      <table>
        <tr>
          <td>Basic media signal processing</td>
          <td id="hide">Undergraduate</td>
          <td id="points">7.5 hp</td>
        </tr>
        <tr>
          <td>Calculus in One Variable 1</td>
          <td id="hide">Undergraduate</td>
          <td id="points">7.5 hp</td>
        </tr>
        <tr>
          <td>Calculus in One Variable 2</td>
          <td id="hide">Undergraduate</td>
          <td id="points">7.5 hp</td>
        </tr>
        <tr>
          <td>Introductory Algebra </td>
          <td id="hide">Undergraduate</td>
          <td id="points">1.5 hp</td>
        </tr>
        <tr>
          <td>Introduction to Discrete Mathematics</td>
          <td id="hide">Undergraduate</td>
          <td id="points">7.5 hp</td>
        </tr>
        <tr>
          <td>Linear Algebra</td>
          <td id="hide">Undergraduate</td>
          <td id="points">7.5 hp</td>
        </tr>
        <tr>
          <td>Statistics for Engineers</td>
          <td id="hide">Undergraduate</td>
          <td id="points">7.5 hp</td>
        </tr>
      </table>

      <h2>Computer science</h2>
      <table>
        <tr>
          <td>Application Development (Java)</td>
          <td id="hide">Undergraduate</td>
          <td id="points">7.5 hp</td>
        </tr>
        <tr>
          <td>Applications Development for Internet</td>
          <td id="hide">Undergraduate</td>
          <td id="points">7.5 hp</td>
        </tr>
        <tr>
          <td>Computer Networks and the Internet</td>
          <td id="hide">Undergraduate</td>
          <td id="points">7.5 hp</td>
        </tr>
        <tr>
          <td>CSS-based Webdesign</td>
          <td id="hide">Undergraduate</td>
          <td id="points">7.5 hp</td>
        </tr>
        <tr>
          <td>Databases and Web Based Systems</td>
          <td id="hide">Undergraduate</td>
          <td id="points">7.5 hp</td>
        </tr>
        <tr>
          <td>Data Structures and Algorithms (C) </td>
          <td id="hide">Undergraduate</td>
          <td id="points">7.5 hp</td>
        </tr>
        <tr>
          <td>Fundamentals of Artificial Intelligence</td>
          <td id="hide">Undergraduate</td>
          <td id="points">7.5 hp</td>
        </tr>
        <tr>
          <td>Human-Computer Interaction</td>
          <td id="hide">Undergraduate</td>
          <td id="points">6 hp</td>
        </tr>
        <tr>
          <td>
            Human-Computer Interaction with Emphasis on Cognition and Design
          </td>
          <td id="hide">Undergraduate</td>
          <td id="points">7.5 hp</td>
        </tr>
        <tr>
          <td>Introductory programming in C and Matlab </td>
          <td id="hide">Undergraduate</td>
          <td id="points">7.5 hp</td>
        </tr>
        <tr>
          <td>Object-Oriented Programming Methodology</td>
          <td id="hide">Undergraduate</td>
          <td id="points">7.5 hp</td>
        </tr>
        <tr>
          <td>Programming Language</td>
          <td id="hide">Undergraduate</td>
          <td id="points">6 hp</td>
        </tr>
        <tr>
          <td>Service development and design for the Internet of Things</td>
          <td id="hide">Undergraduate</td>
          <td id="points">7.5 hp</td>
        </tr>
        <tr>
          <td>Web Technology for Engineers</td>
          <td id="hide">Undergraduate</td>
          <td id="points">7.5 hp</td>
        </tr>
      </table>

      <h2>Design</h2>

      <table>
        <tr>
          <td> Architecture of Mind for Interaction and Design </td>
          <td id="hide">Undergraduate</td>
          <td id="points">7.5 hp</td>
        </tr>
        <tr>
          <td>Design for user experience </td>
          <td id="hide">Undergraduate</td>
          <td id="points">15 hp</td>
        </tr>
        <tr>
          <td>Digital Images</td>
          <td id="hide">Undergraduate</td>
          <td id="points">7.5 hp</td>
        </tr>
        <tr>
          <td>Co-design – Design Dialouges</td>
          <td id="hide">Undergraduate</td>
          <td id="points">7.5 hp</td>
        </tr>
        <tr>
          <td>Cognitive Interaction Design</td>
          <td id="hide">Graduate</td>
          <td id="points">7.5 hp</td>
        </tr>
        <tr>
          <td> Cross-Media Design and Production</td>
          <td id="hide">Graduate</td>
          <td id="points">7.5 hp</td>
        </tr>
        <tr>
          <td>Current Topic in Interaction Technology and Design</td>
          <td id="hide">Graduate</td>
          <td>9 hp</td>
        </tr>
        <tr>
          <td>Image and Graphic Design for the Web</td>
          <td id="hide">Undergraduate</td>
          <td id="points">7.5 hp</td>
        </tr>
        <tr>
          <td>Interaction Technology and Design</td>
          <td id="hide">Undergraduate</td>
          <td id="points">7.5 hp</td>
        </tr>
        <tr>
          <td>Interaction Technology and Media</td>
          <td id="hide">Undergraduate</td>
          <td id="points">7.5 hp</td>
        </tr>
        <tr>
          <td>
            Product Development in Media Technology using the
            "Design-build-test" method
          </td>
          <td id="hide">Graduate</td>
          <td id="points">7.5 hp</td>
        </tr>
        <tr>
          <td>Service Design and Business Models in an Engineering Context</td>
          <td id="hide">Graduate</td>
          <td>7.5 hp</td>
        </tr>
      </table>

      <h2>Other</h2>
      <table>
        <tr>
          <td>Computer Based Narration</td>
          <td id="hide">Undergraduate</td>
          <td id="points">7.5 hp</td>
        </tr>
        <tr>
          <td>Beginner Korean</td>
          <td id="hide">Undergraduate</td>
          <td id="points">6 hp</td>
        </tr>
        <tr>
          <td>English Grammar</td>
          <td id="hide">Undergraduate</td>
          <td id="points">6 hp</td>
        </tr>
        <tr>
          <td>Project Management 1</td>
          <td id="hide">Undergraduate</td>
          <td id="points">7.5 hp</td>
        </tr>
        <tr>
          <td>Social Media Technology </td>
          <td id="hide">Undergraduate</td>
          <td id="points">7.5 hp</td>
        </tr>
        <tr>
          <td>Sustainable development for engineers</td>
          <td id="hide">Undergraduate</td>
          <td id="points">7.5 hp</td>
        </tr>
      </table>
    </div>
  );
};

export default Education;
