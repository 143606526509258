import React from 'react'
import gth from '../../../Assets/gth.png';
import bredvid from '../../../Assets/bredvid.jpeg';


function Coetus(){
  return (
    <div className='portfolio__solwe'>
        <div className = "portfolio__projects">
            <div className ="portfolio__projects-left">
                <div className ="portfolio__projects-left_content">
                    <h1>Good-Tech Hack</h1>
                    <p>Awarded project created in 24 hours during the hackathon Good Tech Hack. The idea is a digital school platform for group projects where the core is an algorithm based on Belbin’s theory about personality types for optimal group dynamics.</p>
                
                    <div className ="portfolio__projects-left_content-description">
                        <h2>DURATION</h2>
                        <p>24 hours</p>

                        <h2>ROLE</h2>
                        <p>UX/UI-design, graphic material</p>

                        <h2>TOOLS</h2>
                        <p>Adobe Photoshop, Adobe Illustrator and Figma</p>

                    </div>
                </div>
            </div>

            <div className = "portfolio__projects-right" id = "coetus">
                <div className = "portfolio__projects-right_img" > <img src ={gth} alt ="img" ></img>
                </div>
            </div>
        </div>

        <div className = "portfolio__projects" id = "reverse">
            <div className = "portfolio__projects-right" id ="coetus2" style={{ backgroundImage: `url(${bredvid})`, 
                    backgroundPosition: 'center',
                    backgroundSize: 'cover',
                    backgroundRepeat: 'no-repeat' } }>
            </div>
            
            <div className = "portfolio__projects-left">
                <div className = "portfolio__projects-left_content">
                    <h1>The challenge</h1>
                    <p>The hackathon Good Tech Hack took place in February 2021. The challenge was to create an innovative solution that society will benefit from, in 24 hours. The theme was based on the eleventh global goal of the United Nations - Sustainable cities and communities. Our team consisted of five participants. We decided on the challenge about social inclusion:
                    <br/>
                    <br/>
                    - How can we increase social inclusion among students so that they perform better in school?

                    </p>
                    <h1>The result</h1>
                    <p>The final result was a prototype and concept idea of a digital platform with the purpose of enabling inclusive group projects that reaches beyond the walls of the classroom - Coetus. The idea is that the groups are generated by an algorithm based on Belbin’s theory about personality types for optimal group dynamics. The platform will simplify the teacher’s work by easy follow-ups on group projects as well as offering the students better insight into his or her role and contribution to the project.

                    <br/> <br/>Our team won the prize as best student team with the motivation that it’s a strong concept that shows visual clarity and a pedagogic layout. With good user insight and high technical feasibility, it’s considered a solution with great potential that easily could scale up.</p>
                </div>
            </div>
        </div>

    </div>
  )
}

export default Coetus